// Colors
$hoverBgColor: #503bff;
$hoverBgColor2: #8293ff;
$spinnerBgColor: rgba(0, 0, 0, 0.1);

$overviewTileBorderColor: #afafaf;
$overviewTileColor: #2417ba;

$sizzlyLight: #e9ecef;
$sizzlyGrey: #adb5bd;
$sizzlyUltraLight: #f8f9ff;

$sizzlyPrimaryUltraLight: #103f6545;
$sizzlyPrimaryLight: rgba(206, 206, 248, 0.727);
$sizzlyPrimaryMedium: #185d94;
$sizzlyPrimary: #103f65;
$sizzlyPrimaryDark: #0a2942;
$sizzlyPrimaryOpacity: #103f6528;
$sizzlyPrimaryWineRed: #e30038;
$sizzlyPrimaryWineRedMedium: #f40440c0;
$sizzlyPrimaryWineRedDark: #590117;
$sizzlyPrimaryWineRedLight: #f5033f6b;
$sizzlyPrimaryWineRedUltraLight: #e3003934;

$systemGreyUltraLight: #f2f2f7;
$systemGreyLight: #d9d9d9;
$systemGreyMedium: #c7c7cc;
$systemGreyDark: #636366;
$systemGreen: #28a745;
$systemYellow: #ffc107;
$systemRed: #dc3545;
$systemRedOpacity: #dc354615;

// Metrics
$radiusSmall: 8px;
$radiusBig: 50rem;
